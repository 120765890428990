import styles from "./LoadingPage.module.css"

import logo from "./assets/logo.png"

const LoadingPage = () => {


    return (
        <div className={styles.loadingPage}>
            <img src={logo} alt="Logo"></img>
            <div className="mt-4">
                <div className="spinner-grow text-primary mx-2" role="status" />
                <div className="spinner-grow text-primary mx-2" role="status" />
                <div className="spinner-grow text-primary mx-2" role="status" />
            </div>
            <span className="visually-hidden">Loading...</span>

        </div>
    )
}

export default LoadingPage;