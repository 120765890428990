import React from "react";
import styles from './HomeBtn.module.css';

import homeLogo from "./assets/icons/home.svg"
import { Link } from "react-router-dom";

const HomeBtn = () => {


    return (

        <span id={styles.homeSpan}>
            <Link to="/main" className={`btn ${styles.homeBtn}`} type="button"><img src={homeLogo} alt="Home" /></Link>
        </span>

    );


}

export default HomeBtn;