import axios from "axios";
import React, { useEffect, useState } from "react"
import styles from './CurrentPrizes.module.css';
import carousel from "bootstrap/js/dist/carousel";


const CurrentPrizes = () => {

    const [prizes, SetPrizes] = useState([]);

    const interval = 2000;

    const [rand, setRand] = useState(0);

    useEffect(() => {
        axios.get("/api/price", { withCredentials: true })
            .then(result => {
                SetPrizes(result.data.message);
                console.log(result.data.message);
                let ra=Math.floor(Math.random() * result.data.message.length)
                setRand(ra);
            })
            .catch(err => console.log("Error: " + err));
        console.log("MOUNTED PRIZES");
        let carouselPromo = document.getElementById("carouselPromo");
        let carouselPromoCopy = new carousel(carouselPromo, { interval: interval })
        carouselPromoCopy.cycle();
    }, [])

    return (
        <>
            <div id="carouselPromo" className="carousel slide mb-4" data-bs-ride="carousel" data-bs-interval={interval}>
                <div className="carousel-inner">
                    {prizes.map((item, idx) => {
                        return (
                            <div className={`carousel-item ${idx == rand ? 'active' : null}`} key={idx} >
                                <img src={process.env.PUBLIC_URL + '/static/prices/' + item.url} className="d-block w-100" alt="..." />
                            </div>)
                    })}


                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselPromo" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselPromo" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    )
}

export default CurrentPrizes;