import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from "./VideoAddInfo.module.css";
import jwt_decode from "jwt-decode";
import { useCookies } from 'react-cookie';
import { useHistory, useParams } from 'react-router-dom';

const VideoAddInfo = ({videoId}) => {

    

    const [video, setVideo] = useState({});
    const [user, setUser] = useState("");
    const [cookies, setCookie] = useCookies();
    const history = useHistory();

    useEffect(() => {

        if ("usertoken" in cookies) {

            axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                .then(result => {

                    const asArray = Object.entries(result.data.message[0]);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    setUser(justStrings);
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
        else {
            console.log("401");
            history.push("/");
        }

        axios.get("/api/video/" + videoId, { withCredentials: true })
            .then(result => {
                setVideo(result.data.message[0]);
            })
            .catch(err => console.log("Error: " + err));
    }, [])

    return (
        <div className={styles.formContainer}>
            <h2 className="text-center">{video.name}</h2>
            <hr />  
            <video src={process.env.PUBLIC_URL + '/static/video/' + video.url} controls className={`w-100`} id="promoVideo"></video>
            <hr />
            <table className="table">
                <tbody>
                    <tr>
                        <th>Vistas actuales</th>
                        <td>{video.currentViews}</td>
                    </tr>
                    <tr>
                        <th>Vistas pagas</th>
                        <td>{video.payedViews}</td>
                    </tr>
                    <tr>
                        <th>Pregunta</th>
                        <td>{video.question}</td>
                    </tr>
                    <tr>
                        <th>Opciones</th>
                        <td>
                            {
                                video.options?.map((item, index) => {
                                    return (
                                        <div key={index} className={`border border-4 rounded mb-2 text-center ${item[1] ? "border-success" : "border-danger"}`}>{item[0]}</div>
                                    );
                                })
                            }
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    );

}

export default VideoAddInfo;