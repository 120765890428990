import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from "./PriceInfo.module.css";
import jwt_decode from "jwt-decode";
import { useCookies } from 'react-cookie';
import { useHistory, useParams } from 'react-router-dom';

const PriceInfo = ({ priceId }) => {


    const [image, setImage] = useState({});
    const [user, setUser] = useState("");
    const [cookies, setCookie] = useCookies();
    const history = useHistory();

    useEffect(() => {

        if ("usertoken" in cookies) {

            axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                .then(result => {

                    const asArray = Object.entries(result.data.message[0]);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    setUser(justStrings);
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
        else {
            console.log("401");
            history.push("/");
        }

        axios.get("/api/price/" + priceId, { withCredentials: true })
            .then(result => {
                setImage(result.data.message[0]);
            })
            .catch(err => console.log("Error: " + err));
    }, [])

    return (
        <div className={styles.formContainer}>
            <h2 className="text-center">{image.name}</h2>
            <hr />
            <img src={process.env.PUBLIC_URL + '/static/prices/' + image.url} alt="File not found" className={`w-100`}/>
            <hr />
            <table className="table">
                <tbody>
                    <tr>
                        <th>Premios entregados</th>
                        <td>{image.given}</td>
                    </tr>
                    <tr>
                        <th>Premios disponibles</th>
                        <td>{image.amount}</td>
                    </tr>
                </tbody>
            </table>

        </div>
    );

}

export default PriceInfo;