import styles from "./LoadingInfo.module.css"

import logo from "./assets/logo.png"

const LoadingInfo = ({message}) => {


    return (
        <div className={styles.LoadingInfo}>
            <img src={logo} alt="Logo"></img>
            <div className="mt-4">
                <div className="spinner-grow text-primary mx-2" role="status" />
                <div className="spinner-grow text-primary mx-2" role="status" />
                <div className="spinner-grow text-primary mx-2" role="status" />
            </div>
            <h2 className={styles.message}>{message}</h2>

        </div>
    )
}

export default LoadingInfo;