import React from "react";
import { useHistory } from "react-router-dom";
import styles from './PrizesBtn.module.css';


const PrizesBtn = () => {

    const history = useHistory();

    const dashboard = () => {
                history.push("/prices");
    }

    return (
        <button className={`btn btn-primary ${styles.prizesBtn}`} type="button" onClick={dashboard}><span>Mis Premios</span></button>
    );


}

export default PrizesBtn;