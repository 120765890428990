import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from "./EditPrice.module.css";
import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';



const EditPrice = ({ priceId }) => {

    const [user, setUser] = useState({});

    const [image, setImage] = useState({});
    const [editImage, setEditImage] = useState({ preview: '', data: '' });

    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const [given, setGiven] = useState(0);
    const [url, setUrl] = useState("");
    

    const [cookies, setCookies] = useCookies();
    const [errors, setErrors] = useState("");


    const history = useHistory();

    useEffect(() => {

        if ("usertoken" in cookies) {

            axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                .then(result => {

                    const asArray = Object.entries(result.data.message[0]);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    setUser(justStrings);
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
        else {
            console.log("401");
            history.push("/");
        }

        axios.get("/api/price/" + priceId, { withCredentials: true })
            .then(result => {
                setImage(result.data.message[0]);
                setName(result.data.message[0].name);
                setUrl(result.data.message[0].url)
                setAmount(result.data.message[0].amount);
                setGiven(result.data.message[0].given);
            })
            .catch(err => console.log("Error: " + err));
    }, [])


    const sendForm = (e) => {
        e.preventDefault()
        let formData = new FormData()

        formData.append('file', editImage.data)
        axios.post("/api/price/save", formData)
            .then(result => {
                console.log(result);
                axios.put("/api/price/" + priceId, {
                    name,
                    amount,
                    given,
                    url,

                }, { withCredentials: true })
                    .then(result => {
                        console.log("DB Result: " + result);
                        setTimeout(() => {
                            history.push("/dashboard");
                        }, 1000)
                    })
                    .catch(err => {
                        setErrors(err.response.data.errors);
                        console.log(err.response.data.errors);
                        console.log("DB Error: " + err);
                    })
            })
            .catch(err => console.log("Error: " + err));
    }

    const handleFileChange = (e) => {

        try {
            const vid = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            }
            setEditImage(vid);
            setUrl(vid.data.name);
        } catch (error) {
            console.log("Error: " + error);
            setEditImage({ preview: '', data: '' });
        }

    }


    return (
        <div className={styles.formContainer}>
            <h2 className="text-center">Editar Premio</h2>
            <form onSubmit={sendForm}>
                <div className="form-group">
                    <label htmlFor="name">Nombre:</label>
                    <input type="text" name="name" id="name" className="form-control" required onChange={(e) => setName(e.target.value)} value={name} />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Cantidad:</label>
                    <input type="number" name="amount" id="amount" className="form-control" required onChange={(e) => setAmount(e.target.value)} value={amount} />
                </div>

                <div className='form-group'>
                    <label htmlFor="file">Imagen:</label>
                    <input type='file' name='file' onChange={handleFileChange} className="form-control" accept='image/png, image/jpeg ,image/jpg, image/webp'></input>
                </div>

                {
                    editImage.preview ?
                    <img src={editImage.preview} alt="File not found" className={`mt-4 mb-2 w-100`} />:
                    <img src={process.env.PUBLIC_URL + '/static/prices/' + image.url} alt="File not found" className={`mt-4 mb-2 w-100`} />
                }
                <br />
                <button type='submit' className="btn btn-primary">Actualizar</button>

            </form>
        </div>
    );

}

export default EditPrice;