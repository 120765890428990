import React, { useEffect, useState } from "react";
import axios from "axios";
import Styles from "./Prize.module.css";
import { useCookies } from "react-cookie";
import Loser from "../Loser/Loser";
import PrizesBtn from "../PrizesBtn/PrizesBtn";

const Prize = ({ winner , userId , onNewPrize}) => {

    const [cookies, setCookie] = useCookies();
    const [reward, setReward] = useState();
    const [firstTime, setFirstTime] = useState(true);

    useEffect(() => {
        axios.get("/api/price/rand", { withCredentials: true })
            .then(result => {
                setReward(result.data.message);
                onNewPrize(result.data.message);
            })
            .catch(err => console.log("Error: " + err));
    }, [])

    const updatePrize = () => {
        if ("usertoken" in cookies) {
            axios.put("/api/price/" + reward._id, {
                name: reward.name,
                url: reward.url,
                given: parseInt(reward.given) + 1,
                amount: reward.amount,
            }, { withCredentials: true })
                .then(result => {
                    console.log(result);
                })
                .catch(err => console.log("Error: " + err));
        }
    }

    if (winner) {

        if (firstTime) {
            updatePrize();
            setFirstTime(false);
        }
        let urlwinner=`https://www.partechapp.com/claimprize/${userId}/${reward._id}`;

        return (

            <div className={Styles.PrizeContainer}>
                <img src={process.env.PUBLIC_URL + '/static/prices/' + reward.url} alt="File not found" className={`w-100`} />
                <h5 className="my-2 text-center">Puedes tomar una captura del código o ver todos tus premios en la sección "Mis Premios" del botón de menú</h5>
                <div className="row justify-content-center m-2">
                    <img src={`https://quickchart.io/qr?text=${urlwinner}&ecLevel=L&margin=0&size=100&format=svg`} alt="Nothing" height="100" width="100" />
                </div>
                <PrizesBtn/>
            </div>
        );
    }
    else {
        return (
            <Loser/>
        );
    }
}

export default Prize;