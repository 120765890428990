import React, { useState } from 'react'
import styles from "./RollDice.module.css"
import Dice from './Dice'
import diceSound from "./assets/sounds/diceSound.wav"
import winSound from "./assets/sounds/winSound.wav"
import loseSound from "./assets/sounds/loseSound.wav"



const RollDice = (props) => {

    const defaultProps = {
        sides: ['one', 'two', 'three',
            'four', 'five', 'six']
    }

    const [dice1, setDice1] = useState("one");
    const [dice2, setDice2] = useState("one");
    const [rolling, setRolling] = useState(false);
    const [rollingEnable, setRollingEnable] = useState(false);

    const roll = () => {
        const { sides } = defaultProps;

        let Throw = Math.floor(Math.random() * (props.load));
        let winThrow = Throw == (props.load - 1) ? true : false;

        let aux1 = sides[Math.floor(Math.random() * sides.length)];
        let aux2 = aux1;
        if (winThrow == false || props.prize.amount == props.prize.given) {
            while (aux2 == aux1) {
                aux2 = sides[Math.floor(Math.random() * sides.length)]
            }
        }

        setDice1(aux1);
        setDice2(aux2);
        setRolling(true);
        setRollingEnable(true);

        var diceRoll = document.getElementById("diceRoll");
        diceRoll.pause();
        diceRoll.currentTime = 0;
        var winRoll = document.getElementById("winRoll");
        winRoll.pause();
        winRoll.currentTime = 0;
        var loseRoll = document.getElementById("loseRoll");
        loseRoll.pause();
        loseRoll.currentTime = 0;

        diceRoll.play();
        setTimeout(() => {
            setRolling(false);
            setTimeout(() => {
                setRollingEnable(false);
            }, 3000)
            diceRoll.pause();
            diceRoll.currentTime = 0;
            if (aux1 == aux2) {
                winRoll.play();
            }
            else {
                loseRoll.play();
            }
            let newDices = { dice1: aux1, dice2: aux2 };
            props.onNewDices(newDices);
        }, 1000)
    }



    return (

        <div className={styles.RollDice}>
            <div className={styles.RollDiceContainer}>
                <Dice face={dice1} rolling={rolling} />
                <Dice face={dice2} rolling={rolling} />
            </div>
            <button className="btn btn-primary" disabled={rollingEnable} onClick={roll}>
                {rolling ? 'Tirando' : 'Tirar dados'}
            </button>

            <audio src={winSound} id="winRoll"></audio>
            <audio src={loseSound} id="loseRoll"></audio>
            <audio src={diceSound} id="diceRoll"></audio>
        </div>
    );

}


export default RollDice