import UserMenu from '../UserMenu/UserMenu';
import styles from "./PrizesPage.module.css";


import HomeBtn from '../HomeBtn/HomeBtn';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import BackBtn from '../BackBtn/BackBtn';
import axios from 'axios';
import { getAllIndexes, uniqueItems } from '../Utils/arrays';

const PrizesPage = () => {

    const [user, setUser] = useState(null);
    const [cookies, setCookie] = useCookies();
    const history = useHistory();
    const [init, setInit] = useState(true);
    const [prizes, setPrizes] = useState(null);
    const [prizeGroups, setPrizeGroups] = useState([]);
    const [finalGroups, setFinalGroups] = useState([]);

    useEffect(() => {
        if (init) {
            if ("usertoken" in cookies) {
                axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                    .then(result => {

                        const asArray = Object.entries(result.data.message[0]);
                        const filtered = asArray.filter(([key, value]) => key !== 'password');
                        const justStrings = Object.fromEntries(filtered);
                        setUser(justStrings);
                    })
                    .catch(err => {
                        console.log("Error: " + err);
                        history.push("/");
                    });
            }
            else {
                console.log("401");
                history.push("/");
            }
            setInit(false);
        }
    }, [])

    useEffect(() => {
        if (user !== null) {
            console.log(user);
            setPrizeGroups(uniqueItems(user.prizes));

            axios.get("/api/price/", { withCredentials: true })
                .then(result => {
                    setPrizes(result.data.message);
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
    }, [user])

    useEffect(() => {
        if (prizes !== null) {
            for (const idx in prizeGroups) {
                for (const item in prizes) {
                    if (prizeGroups[idx] == prizes[item]._id) {
                        prizeGroups[idx] = prizes[item];
                        let allItems = getAllIndexes(user.prizes, prizeGroups[idx]._id)
                        prizeGroups[idx].amount = (allItems.length);
                        prizeGroups[idx].given = 0;
                        for (const index in allItems) {
                            if (user.claimDate[allItems[index]] !== '') {
                                prizeGroups[idx].given += 1;
                            }
                        }
                    }
                }
            }
            console.log(prizeGroups);
            setFinalGroups(prizeGroups);
        }

    }, [prizes])

    return (
        <div className={`${styles.main_screen}`}>
            <div className={`sticky-top ${styles.topBar}`}>
                <div className={styles.flex_1}>
                    <UserMenu />
                </div>
                <h1>PAR</h1>
                <div className={styles.flex_1}>
                    <BackBtn />
                </div>
            </div>
            <div className={`${styles.admin_main_content}`}>
                <table className="table table-hover">
                    <thead >
                        <tr>
                            <th>Nombre</th>
                            <th>Reclamados</th>
                            <th>QR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {finalGroups?.map((item, index) => {
                            if (item.name != undefined) {
                                let urlwinner = `https://www.partechapp.com/claimprize/${user._id}/${item._id}`;
                                console.log(item);
                                return (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{`${item.given} / ${item.amount}`}</td>
                                        <td>
                                            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#modal${item._id + index}`} >QR</button>
                                            <div className={`modal fade`} id={`modal${item._id + index}`} tabIndex="-1" aria-labelledby={`modal${item._id + index}Label`} aria-hidden="true">
                                                <div className={`modal-dialog modal-dialog-centered modal-dialog-scrollable`}>
                                                    <div className={`modal-content ${styles.modalContainer}`}>
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id={`modal${item._id + index}Label`}>{item.name}</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>

                                                        <div className={`modal-body ${styles.modalContent}`}>
                                                            <img src={process.env.PUBLIC_URL + '/static/prices/' + item.url} alt="File not found" className={`w-100`} />
                                                            <h6 className="text-center my-2">Acercate a un punto de venta y presenta tu código para reclamar el premio</h6>
                                                            <img src={`https://quickchart.io/qr?text=${urlwinner}&ecLevel=L&margin=0&size=100&format=svg`} alt="Nothing" className={`w-75`} />
                                                            <h6 className="text-center my-2">No sabes dónde reclamar tu premio? <a href="https://wa.link/blndd3" target="_blank">Contáctanos</a></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                    </tbody>
                </table>
            </div>

            <div className={`${styles.bottomBar}`}>
                <HomeBtn />
            </div>
        </div>
    );
}

export default PrizesPage;