import React from "react";
import { useHistory } from "react-router-dom";
import Styles from "./Loser.module.css";


const Loser = () => {
    const history = useHistory();
    const reload = (e) => {
        e.preventDefault();
        history.go(0)
    }


    return (
        <div className={Styles.LoserContainer}>
            <h4 className="mb-2 text-center">Puedes jugar TODAS las veces que quieras </h4>
            <button className="btn btn-primary" onClick={reload}>Jugar de nuevo</button>
        </div>
    );
}

export default Loser;