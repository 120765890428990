import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AdminPage from './components/AdminPage/AdminPage';
import MainPage from './components/MainPage/MainPage';
import VideoPage from './components/VideoPage/VideoPage';
import WelcomePage from './components/WelcomePage/WelcomePage';
import AddMetrics from './components/AddMetrics/AddMetrics'
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import AdminPageEdit from './components/AdminPageEdit/AdminPageEdit';
import CreatePricePage from './components/CreatePricePage/CreatePricePage';
import PricePageEdit from './components/PricePageEdit/PricePageEdit';
import ClaimPrizePage from './components/ClaimPrizePage/ClaimPrizePage';
import PrizeScanner from './components/PrizeScanner/PrizeScanner';
import PrizesPage from './components/PrizesPage/PrizesPage';
import { useEffect } from 'react';
import axios from 'axios';
import GameSettings from './components/GameSettings/GameSettings';


const App = () => {

  const isProduction = process.env.NODE_ENV === 'production';

  const updateCache = () => {
    fetch('meta.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(result => result.json())
      .then(result => {
        let version = require('../package.json').version;
        console.log("PKG: " + version);
        let currentVersion = result.version;
        console.log("META: " + currentVersion)
        let localVersion = localStorage.getItem("version");
        console.log("LOCAL: " + localVersion);
        if (version !== currentVersion || version !== localVersion || currentVersion !== localVersion) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          console.log('Complete Cache Cleared');
          localStorage.setItem("version", currentVersion);
          window.opener = null;
          window.open("", "_self");
          window.close();
        }

      })
  }
  useEffect(() => {
    axios.get("/api/game/", { withCredentials: true })
      .then(result => {
        if (result.data.message.length === 0) {
          console.log("EMPTY");
          axios.post("/api/game/", { load: 6 }, { withCredentials: true })
            .then(result => {
              console.log("CREATING FIRST GAME");
              console.log(result);
            })
            .catch(err => {
              console.log("Error: " + err);
            });

        }
      })
      .catch(err => {
        console.log("Error: " + err);
      });
    document.getElementById("app").addEventListener("contextmenu", (e) => {
      if (isProduction) {
        e.preventDefault();
      }
    })
    if (isProduction) {
      //updateCache();
    }
  }, [])

  return (
    <div className="" id="app">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact render={() => <WelcomePage />} />
          <Route path="/main" exact render={() => <MainPage />} />
          <Route path="/play" exact render={() => <VideoPage />} />
          <Route path="/create/video" exact render={() => <AdminPage />} />
          <Route path="/create/price" exact render={() => <CreatePricePage />} />
          <Route path="/edit/video/:id" exact render={() => <AdminPageEdit />} />
          <Route path="/edit/price/:id" exact render={() => <PricePageEdit />} />
          <Route path="/metrics" exact render={() => <AddMetrics />} />
          <Route path="/dashboard" exact render={() => <AdminDashboard />} />
          <Route path="/claimprize/:userId/:prizeId" exact render={() => <ClaimPrizePage />} />
          <Route path="/scanprize" exact render={() => <PrizeScanner />} />
          <Route path="/prices" exact render={() => <PrizesPage />} />
          <Route path="/settings" exact render={() => <GameSettings />} />

        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
