import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from './Login.module.css';

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState("");

    const history = useHistory();

    const login = (e) => {
        e.preventDefault();

        axios.post("/api/login", {
            email,
            password
        }, { withCredentials: true })
            .then(res => {
                //console.log(res);
                if (res.data.error) {
                    //Si existe error
                    if (email === "") {
                        setErrors("Ingrese un correo")
                    }
                    else if (password === "") {
                        setErrors("Ingrese una contraseña")
                    }
                    else {
                        setErrors(res.data.message);
                    }


                }
                else {
                    localStorage.setItem("user", JSON.stringify(res.data.user))
                    console.log(res.data.user)
                    history.push("/main");
                }
            })
            .catch(err => console.log(err));
    }

    return (

        <div className={styles.loginCard}>
            <form onSubmit={(e) => login(e)}>
                <h2 className="text-center">Accede a tu cuenta</h2>
                <div className={styles.formGroup}>
                    <label htmlFor="">Email</label>
                    <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="">Contraseña</label>
                    <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="mb-2">
                    {errors ? <span className="text-danger">{errors}</span> : null}
                </div>
                <button type="submit" className={`btn ${styles.btnBlue}`}>Entrar</button>

            </form>

        </div>

    );


}

export default Login;