import UserMenu from '../UserMenu/UserMenu';
import styles from "./PrizeScanner.module.css";
import HomeBtn from "../HomeBtn/HomeBtn";
import { QrReader } from "react-qr-reader";
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useCookies } from 'react-cookie';

const PrizeScanner = () => {

    const history = useHistory();
    const [data, setData] = useState('No result');
    const [user, setUser] = useState("");
    const [cookies, setCookie] = useCookies();

    const [cameraPos, setCameraPos] = useState({facingMode: 'environment'})

    useEffect(() => {
        if (data.includes("https://www.partechapp.com/claimprize")) {
            console.log("CODE OK");
            let url = data.replace("https://www.partechapp.com/", "");
            history.push(url);
        }
        else {
            console.log("CODE INCORRECT");
        }
    }, [data])

    useEffect(() => {

        if ("usertoken" in cookies) {
            axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                .then(result => {

                    const asArray = Object.entries(result.data.message[0]);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    setUser(justStrings);
                    if (justStrings.category !== "cashier" && justStrings.category !== "admin") {
                        history.push("/");
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
        else {
            console.log("401");
            history.push("/");
        }

    }, []);

    return (
        <div className={`${styles.main_screen}`}>
            <div className={`sticky-top ${styles.topBar}`}>
                <div className={styles.flex_1}>
                    <UserMenu />
                </div>
                <h1>PAR</h1>
                <div className={styles.flex_1}>
                </div>
            </div>
            <div className={`${styles.main_content} ${styles.centerContent}`}>
                <QrReader
                    constraints={cameraPos}
                    onResult={(result, error) => {
                        if (!!result) {
                            setData(result?.text);
                        }

                        if (!!error) {
                            //console.info(error);
                        }
                    }}
                    className={styles.qrCam}
                ></QrReader>

            </div>

            <div className={`${styles.bottomBar}`}>
                <HomeBtn />
            </div>
        </div>
    );
}

export default PrizeScanner;