import React from "react";
import styles from './BackBtn.module.css';
import backLogo from "./assets/icons/arrowBack.svg"
import { useHistory } from "react-router-dom";



const BackBtn = () => {

    const history = useHistory();
    return (

        <span className={styles.createSpan}>
            <button className={`btn ${styles.menuBtn}`} onClick={history.goBack} type="button"><img src={backLogo} alt="Menu" /></button>
        </span>

    );


}

export default BackBtn;