import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from './PlayVideo.module.css';
import { Collapse } from "bootstrap";
import RollDice from "../RollDice/RollDice";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Prize from "../Prize/Prize";
import { getCurrentDate } from "../Utils/date";
import CurrentPrizes from "../CurrentPrizes/CurrentPrizes";
import LoadingMain from "../LoadingMain/LoadingMain";
import Loser from "../Loser/Loser";

const PlayVideo = () => {

    const [user, setUser] = useState({});
    const [addVideo, setAddVideo] = useState({});
    const [cookies, setCookie] = useCookies();
    const history = useHistory();

    const [winner, setWinner] = useState(false);
    const [currentPrize, setCurrentPrize] = useState(null);

    const [loaded, setLoaded] = useState(false);

    const [dicesLoad, setDicesLoad] = useState(6);

    const updateCurrentPrize = (thisPrize) => {
        setCurrentPrize(thisPrize);
    }

    const [dices, setDices] = useState({
        dice1: "dice1",
        dice2: "dice2"
    });

    const updateDices = (newDices) => {
        setDices(newDices);
    }

    useEffect(() => {

        if ("usertoken" in cookies) {
            axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                .then(result => {

                    const asArray = Object.entries(result.data.message[0]);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    setUser(justStrings);
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
        else {
            console.log("401");
            history.push("/");
        }

        axios.get("/api/video/rand", { withCredentials: true })
            .then(result => {
                setAddVideo(result.data.message);
            })
            .catch(err => console.log("Error: " + err));
        axios.get("/api/game", { withCredentials: true })
            .then(result => {
                setDicesLoad(result.data.message[0].load);
            })
            .catch(err => console.log("Error: " + err));


        let promoVideo = document.getElementById("promoVideo");
        promoVideo.load();
        promoVideo.addEventListener("canplay", () => {
            let btnStart = document.getElementById("btnStart");
            btnStart.disabled = false;
            btnStart.addEventListener("click", playVideo);
            setLoaded(true);
        })
        promoVideo.addEventListener("canplaythrough", () => {
            let btnStart = document.getElementById("btnStart");
            btnStart.disabled = false;
            btnStart.addEventListener("click", playVideo);
            setLoaded(true);
        })
    }, [])


    useEffect(() => {

        if (dices.dice1 === dices.dice2) {
            updateUser(10, 0, currentPrize._id);
            console.log("COINS UPDATED")
            setWinner(true);

            setTimeout(() => {
                var questions = document.getElementById("questions");
                var questionsCollapse = new Collapse(questions, { toggle: false });
                var promoVideo = document.getElementById("promoVideo");
                var promoVideoCollapse = new Collapse(promoVideo, { toggle: false });
                var dices = document.getElementById("dices");
                var dicesCollapse = new Collapse(dices, { toggle: false });
                var winners = document.getElementById("winner")
                var winnersCollapse = new Collapse(winners, { toggle: false });
                var loser = document.getElementById("loser");
                var loserCollapse = new Collapse(loser,{toggle:false});

                var mainHeader = document.getElementById("mainHeader");
                mainHeader.innerHTML="GANASTE"; 

                questionsCollapse.hide();
                promoVideoCollapse.hide();
                dicesCollapse.hide();
                loserCollapse.hide();
                winnersCollapse.show();
                

            }, 1000)


        }
        else if (dices.dice1 !== "dice1") {

            setTimeout(() => {
                var questions = document.getElementById("questions");
                var questionsCollapse = new Collapse(questions, { toggle: false });
                var promoVideo = document.getElementById("promoVideo");
                var promoVideoCollapse = new Collapse(promoVideo, { toggle: false });
                var dices = document.getElementById("dices");
                var dicesCollapse = new Collapse(dices, { toggle: false });
                var winners = document.getElementById("winner")
                var winnersCollapse = new Collapse(winners, { toggle: false });
                var loser = document.getElementById("loser");
                var loserCollapse = new Collapse(loser,{toggle:false});

                var mainHeader = document.getElementById("mainHeader");
                mainHeader.innerHTML="Sigue Intentándolo"; 
                
                questionsCollapse.hide();
                promoVideoCollapse.hide();
                dicesCollapse.hide();
                winnersCollapse.hide();
                loserCollapse.show();
            }, 1000)
        }
    }, [dices])

    const updateUser = (coin, exp, prize) => {

        console.log("EXP:" + user.experience);
        console.log("COIN:" + user.coins);
        //Para premio
        if (user.prizes) {
            if (prize) {
                user.prizes.push(prize);
                console.log(user.prizes);
            }
        }
        else {
            if (prize) {
                user.prizes = [prize];
            }
            else {
                user.prizes = [];
            }
        }
        //Para Fecha de premio
        if (user.winDate) {
            if (prize) {
                user.winDate.push(getCurrentDate());
                console.log(user.winDate);
            }
        }
        else {
            if (prize) {
                user.winDate = [getCurrentDate()];
            }
            else {
                user.winDate = [];
            }
        }
        //Para Fecha de reclamo
        if (user.claimDate) {
            if (prize) {
                user.claimDate.push("");
                console.log(user.claimDate);
            }
        }
        else {
            if (prize) {
                user.claimDate = [""];
            }
            else {
                user.claimDate = [];
            }
        }

        if ("usertoken" in cookies) {
            axios.put("/api/users/" + jwt_decode(cookies.usertoken)._id, {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                password: user.password,
                birthdate: user.birthdate,
                experience: user.experience === null ? exp : parseInt(user.experience) + exp,
                coins: user.coins === null ? coin : parseInt(user.coins) + coin,
                prizes: user.prizes,
                winDate: user.winDate,
                claimDate: user.claimDate,
            }, { withCredentials: true })
                .then(result => {
                    const asArray = Object.entries(result.data);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    setUser(justStrings);
                })
                .catch(err => console.log("Error: " + err));
        }
    }

    const updateVideo = () => {
        if ("usertoken" in cookies) {
            axios.put("/api/video/" + addVideo._id, {
                name: addVideo.name,
                url: addVideo.url,
                currentViews: parseInt(addVideo.currentViews) + 1,
                payedViews: addVideo.payedViews,
                question: addVideo.question,
                options: addVideo.options
            }, { withCredentials: true })
                .then(result => {
                    //console.log(result);
                })
                .catch(err => console.log("Error: " + err));
        }
    }


    const playVideo = () => {
        let CurrentPrizeCollapse = document.getElementById("CurrentPrizeCollapse");
        let CurrentPrizeCollapseAux = new Collapse(CurrentPrizeCollapse, { toggle: false });
        CurrentPrizeCollapseAux.hide();

        var video = document.getElementById("promoVideo");
        let videoCollapse = new Collapse(video, { toggle: false });
        videoCollapse.show();
        console.log("PLAYED VIDEO");
        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.webkitRequestFullscreen) { /* Safari */
            video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) { /* IE11 */
            video.msRequestFullscreen();
        }
        video.play();
        var btnStart = document.getElementById("btnStart");
        var btnStartCollapse = new Collapse(btnStart, { toggle: false });
        btnStartCollapse.hide();
    }

    const endVideo = () => {
        var questions = document.getElementById("questions");
        var questionsCollapse = new Collapse(questions, { toggle: false });
        var promoVideo = document.getElementById("promoVideo");
        var promoVideoCollapse = new Collapse(promoVideo, { toggle: false });
        setTimeout(() => {
            if (promoVideo.exitFullscreen) {
                promoVideo.exitFullscreen();
            } else if (promoVideo.webkitExitFullscreen) { /* Safari */
                promoVideo.webkitExitFullscreen();
            } else if (promoVideo.msExitFullscreen) { /* IE11 */
                promoVideo.msExitFullscreen();
            }
            questionsCollapse.show();
            promoVideoCollapse.hide();
        }, 500);
        updateVideo();
        let mainHeader = document.getElementById("mainHeader");
        mainHeader.innerHTML = addVideo.name;
    }

    const checkAnswer = (e, selection) => {
        e.target.classList.remove("btn-outline-primary");
        if (selection[1]) {
            e.target.classList.add("btn-outline-success");

            updateUser(0, 10, null);

            setTimeout(() => {
                var questions = document.getElementById("questions");
                var questionsCollapse = new Collapse(questions, { toggle: false });
                var promoVideo = document.getElementById("promoVideo");
                var promoVideoCollapse = new Collapse(promoVideo, { toggle: false });
                var dices = document.getElementById("dices");
                var dicesCollapse = new Collapse(dices, { toggle: false });

                questionsCollapse.hide();
                promoVideoCollapse.hide();
                dicesCollapse.show();

            }, 1000)
        }
        else {
            e.target.classList.add("btn-outline-danger");

            setTimeout(() => {
                var questions = document.getElementById("questions");
                var questionsCollapse = new Collapse(questions, { toggle: false });
                var promoVideo = document.getElementById("promoVideo");
                var promoVideoCollapse = new Collapse(promoVideo, { toggle: false });
                var dices = document.getElementById("dices");
                var dicesCollapse = new Collapse(dices, { toggle: false });

                questionsCollapse.hide();
                promoVideoCollapse.show();
                dicesCollapse.hide();
                playVideo();

            }, 1000)
        }
    }

    if (addVideo === null || addVideo === undefined || addVideo === [] || addVideo === "") {
        return (
            <div className={`${styles.promoContainer}`}>
                <h1 className={styles.h1}>No hay videos disponibles en el momento</h1>
            </div>
        );
    }
    else {
        return (
            <>
                {loaded == false ? <LoadingMain /> : null}
                <div className={`${styles.promoContainer} ${loaded == true ? null : 'visually-hidden'}`}>
                    <h1 className={styles.h1} id="mainHeader">Recompensas Disponibles</h1>
                    <hr />
                    <div className="collapse show" id="CurrentPrizeCollapse">
                        <CurrentPrizes />
                    </div>
                    <video onEnded={endVideo} src={process.env.PUBLIC_URL + '/static/video/' + addVideo.url} onContextMenu={(e) => e.preventDefault()} className={`w-100 collapse  ${styles.video}`} id="promoVideo"></video>
                    <button className={`btn btn-primary collapse show ${styles.playBtn}`} disabled id="btnStart">Comenzar</button>
                    <div className="collapse" id="questions">
                        <h2 className="text-center">{addVideo.question}</h2>
                        {
                            addVideo.options?.map((item, index) => {
                                return (
                                    <button key={index + 1} className="btn btn-outline-primary form-control mb-2 mt-2" onClick={(e) => checkAnswer(e, item)}>{item[0]}</button>
                                );
                            })
                        }
                    </div>
                    <div className="collapse row justify-content-center" id="dices">
                        <RollDice onNewDices={updateDices} load={dicesLoad} prize={currentPrize} />
                    </div>
                    <div className="collapse row justify-content-center" id="winner">
                        <Prize winner={winner} userId={user._id} onNewPrize={updateCurrentPrize} prize={currentPrize} />
                    </div>
                    <div className="collapse row justify-content-center" id="loser">
                        <Loser />
                    </div>
                </div>
            </>
        );
    }
}

export default PlayVideo;