import React, { useEffect} from "react";
import styles from './DailyPromos.module.css';
import carousel from "bootstrap/js/dist/carousel";

const DailyPromos = () => {

    const interval = 5000;

    useEffect(() => {
        console.log("MOUNTED");
        let carouselPromo = document.getElementById("carouselPromo");
        let carouselPromoCopy = new carousel(carouselPromo, {interval: interval})
        carouselPromoCopy.cycle();
    }, [])

    return (

        <div className={styles.promoContainer}>
            <h1 className={styles.h1}>Promocioness</h1>
            <div id="carouselPromo" className="carousel slide" data-bs-ride="carousel" data-bs-interval={interval}>
                <div className="carousel-inner">
                    <div className="carousel-item active " >
                        <img src={process.env.PUBLIC_URL + '/static/img/DayPromos/promo1.jpg'} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item " >
                        <img src={process.env.PUBLIC_URL + '/static/img/DayPromos/promo2.jpg'} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item" >
                        <img src={process.env.PUBLIC_URL + '/static/img/DayPromos/promo3.jpg'} className="d-block w-100" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselPromo" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselPromo" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>

    );

}

export default DailyPromos;