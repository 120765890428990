import UserMenu from '../UserMenu/UserMenu';
import styles from "./GameSettings.module.css";


import HomeBtn from '../HomeBtn/HomeBtn';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import BackBtn from '../BackBtn/BackBtn';
import axios from 'axios';


const GameSettings = () => {

    const [user, setUser] = useState(null);
    const [cookies, setCookie] = useCookies();
    const history = useHistory();

    const [game, setGame] = useState({});
    const [gameLoad, setGameLoad] = useState("");
    const [userAmount, setUserAmount] = useState();

    const updateState = () => {
        if ("usertoken" in cookies) {
            axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                .then(result => {

                    const asArray = Object.entries(result.data.message[0]);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    setUser(justStrings);
                    if (justStrings.category !== "admin") {
                        history.push("/");
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
        else {
            console.log("401");
            history.push("/");
        }
        axios.get("/api/game/", { withCredentials: true })
            .then(result => {
                setGame(result.data.message[0]);
                setGameLoad(result.data.message[0].load);
            })
            .catch(err => {
                console.log("Error: " + err);
            });
        axios.get("/api/users/amount", { withCredentials: true })
            .then(result => {
                setUserAmount(result.data);
            })
            .catch(err => {
                console.log("Error: " + err);
            });
    }

    useEffect(() => {
        updateState();
    }, [])

    const updateSettings = (e) => {
        axios.put("/api/game/" + game._id,
            {
                load: gameLoad,
            },
            { withCredentials: true })
            .then(result => {
                console.log("UPDATING GAME");
                console.log(result);
                updateState();
            })
            .catch(err => {
                console.log("Error: " + err);
            });
    }

    return (
        <div className={`${styles.main_screen}`}>
            <div className={`sticky-top ${styles.topBar}`}>
                <div className={styles.flex_1}>
                    <UserMenu />
                </div>
                <h1>PAR</h1>
                <div className={styles.flex_1}>
                    <BackBtn />
                </div>
            </div>
            <div className={`${styles.main_content}`}>
                <div className={`${styles.infoContainer}`}>
                    <div>
                        <label>Usuarios</label>
                        <span className="form-control">{userAmount}</span>
                    </div>
                    <div>
                        <label>Carga</label>
                        <input type="number" min="6" className="form-control" onChange={(e) => setGameLoad(e.target.value)} value={gameLoad} />
                    </div>
                    <button id="actualizar" type="button" className="btn btn-primary mt-2 " onClick={(e) => updateSettings(e)} >Actualizar</button>
                </div>
            </div>
            <div className={`${styles.bottomBar}`}>
                <HomeBtn />
            </div>
        </div>
    );
}

export default GameSettings;