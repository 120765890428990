import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useLoggedUser = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState("");
    const history = useHistory();

    const [checkLoggedStatus,setCheckLoggedStatus]  = useState(true);

    const logPrev = (user) => {
        axios.post("/api/login", {
            email: user.email,
            password: user.password
        }, { withCredentials: true })
            .then(res => {

                if (res.data.error) {
                    //Si existe error
                    if (email === "") {
                        setErrors("Ingrese un correo")
                    }
                    else if (password === "") {
                        setErrors("Ingrese una contraseña")
                    }
                    else {
                        setErrors(res.data.message);
                    }
                    localStorage.clear();
                }
                else {
                    setCheckLoggedStatus(true);
                    localStorage.setItem("user", JSON.stringify(res.data.user))
                    setTimeout(() => {
                        history.push("/main");   
                    }, 3000);
                    
                }
            })
            .catch(err => console.log(err));
    }

    const checkLoggedUser = () => {
        let loggedUser = localStorage.getItem("user");
        loggedUser = JSON.parse(loggedUser);
        if (loggedUser) {
            logPrev(loggedUser);
        }
        else{
            setCheckLoggedStatus(false);
        }
    }
    return [checkLoggedStatus, checkLoggedUser]

}

export default useLoggedUser;