import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from './UserMenu.module.css';
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";

import menuLogo from "./assets/icons/menu.svg"
import accountLogo from "./assets/icons/account.svg"
import LogoutBtn from "../LogoutBtn/LogoutBtn";
import AdminBtn from "../AdminBtn/AdminBtn";
import ScanBtn from "../ScanBtn/ScanBtn";
import PrizesBtn from "../PrizesBtn/PrizesBtn";
import GameSettingsBtn from "../GameSettingsBtn/GameSettingsBtn";

import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

const UserMenu = () => {

    const [user, setUser] = useState("");
    const [cookies, setCookie] = useCookies();
    const history = useHistory();

    useEffect(() => {

        if ("usertoken" in cookies) {
            axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                .then(result => {

                    const asArray = Object.entries(result.data.message[0]);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    setUser(justStrings);
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
        else {
            console.log("401");
            history.push("/");
        }
    }, [])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <button className={`btn ${styles.menuBtn}`} type="button" onClick={handleShow}><img src={menuLogo} alt="Menu" /></button>
            <Offcanvas className={styles.userMenu} show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <img src={accountLogo} alt="Account" />
                    <Offcanvas.Title>{user.firstName + " " + user.lastName}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        <table className="table visually-hidden">
                            <tbody>
                                <tr>
                                    <th>Par Coins:</th>
                                    <td>{user.coins}</td>
                                </tr>
                                <tr>
                                    <th>Exp:</th>
                                    <td>{user.experience}</td>
                                </tr>
                            </tbody>
                        </table>
                        {(user.category !== "cashier") ? <PrizesBtn /> : null}
                        {(user.category === "admin") ? <AdminBtn /> : null}
                        {(user.category === "admin") ? <GameSettingsBtn /> : null}
                        {(user.category === "admin" || user.category === "cashier") ? <ScanBtn /> : null}
                        <LogoutBtn />
                    </div>
                    <hr></hr>
                    <div className={`${styles.columnInfo}`}>
                        <a href="https://wa.link/bpnxc8" target="_blank">Contáctanos</a>
                        <a href={process.env.PUBLIC_URL + '/static/pdf/terminos.pdf'} download>Términos y Condiciones</a>
                    </div>

                    <div className={styles.menuFooter}>
                        <hr></hr>
                        <p className="text-center my-0">Desarrollado por</p>
                        <p className="text-center"><a href="https://www.rivarengineering.com/" target="_blank">RIVAR ENGINEERING SAS</a></p>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>


    );


}

export default UserMenu;