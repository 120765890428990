import React from "react";
import styles from './CreateVideoBtn.module.css';


import plusLogo from "./assets/icons/plus.svg"
import { Link } from "react-router-dom";



const CreateVideoBtn = () => {


    return (
            <Link to="/create/video" className={`btn ${styles.addVideoBtn}`} type="button"><img src={plusLogo} alt="Menu" /></Link>
    );


}

export default CreateVideoBtn;