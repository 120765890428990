import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from './LogoutBtn.module.css';


import logoutLogo from "./assets/icons/logout.svg"


const LogoutBtn = () => {

    const history = useHistory();

    const logout = () => {
        axios.get("/api/logout/", { withCredentials: true })
            .then(result => {
                history.push("/");
                localStorage.clear();
            })
            .catch(err => console.log("Error: " + err));
    }

    return (

        <button className={`btn btn-danger ${styles.logoutBtn}`} type="button" onClick={logout}><img src={logoutLogo} alt="Logout" /><span>Logout</span></button>
    );


}

export default LogoutBtn;