import React, { useState } from 'react';
import axios from 'axios';
import styles from "./AddMetrics.module.css";
import { Link } from "react-router-dom";
import VideoAddInfo from '../VideoAddInfo/VideoAddInfo';
import CreateVideoBtn from '../CreateVideoBtn/CreateVideoBtn';

const AddMetrics = () => {

    const [addVideos, setAddVideos] = useState([])


    const getVideos = () => {
        axios.get("/api/video/", { withCredentials: true })
            .then(result => {
                setAddVideos(result.data.message);
            })
            .catch(err => console.log("Error: " + err));
    }

    const deleteVideo = (videoId) => {

        axios.delete("/api/video/" + videoId, { withCredentials: true })
            .then(result => {
                getVideos();
            })
            .catch(err => console.log("Error: " + err));

    }
    useState(() => {
        getVideos();
    }, [])

    return (
        <div className={styles.AddMetricsformContainer}>
            <div className={styles.AddMetricsTitle}>
                <div className={styles.AddMetricsFlex1}></div>
                <h2 className="text-center mx-3">Comerciales Actuales</h2>
                <div className={styles.AddMetricsFlex1}><CreateVideoBtn /></div>
            </div>
            <table className="table table-hover">
                <thead >
                    <tr>
                        <th>Nombre</th>
                        <th>Vistas</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {addVideos?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{`${item.currentViews} / ${item.payedViews}`}</td>
                                <td>
                                    <Link to={`/video/view/${item._id}`} className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target={`#modal${item._id + index}`}>Info</Link>

                                    <div className={`modal fade`} id={`modal${item._id + index}`} tabIndex="-1" aria-labelledby={`modal${item._id + index}Label`} aria-hidden="true">
                                        <div className={`modal-dialog modal-dialog-centered modal-dialog-scrollable`}>
                                            <div className={`modal-content ${styles.modalContainer}`}>
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id={`modal${item._id + index}Label`}>Información</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <VideoAddInfo videoId={item._id} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Link to={`/edit/video/${item._id}`} className="btn btn-warning me-2">Editar</Link>
                                    <button className="btn btn-danger" data-bs-toggle="modal" data-bs-target={`#modalDelete${item._id + index}`} >Borrar</button>
                                    <div className="modal fade" id={`modalDelete${item._id + index}`} tabIndex="-1" aria-labelledby={`modalDelete${item._id + index}Label`} aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Advertencia</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    Ésta acción eliminará de manera permanente el elemento, ¿Desea continuar?
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Cancelar</button>
                                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => { deleteVideo(item._id) }}>Borrar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

}

export default AddMetrics;