import React, { useEffect } from "react";
import Login from "../Login/Login";
import SignIn from "../SignIn/SignIn"
import styles from './WelcomePage.module.css';
import { Collapse } from "bootstrap";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import useLoggedUser from "../../hooks/useLoggedUser/useLoggedUser";
import LoadingPage from "../LoadingPage/LoadingPage";
import InstallBanner from "../InstallBanner/InstallBanner";


const WelcomePage = () => {

    const [cookies, setCookies] = useCookies();
    const history = useHistory();
    const [checkLoggedStatus, checkLoggedUser] = useLoggedUser();

    useEffect(() => {
        if ("usertoken" in cookies) {
            setTimeout(() => {
                history.push("/main");
            }, 3000);;
        }
        else {
            checkLoggedUser();
        }
    }, [])
    useEffect(() => {
        console.log(checkLoggedStatus)
    }, [checkLoggedStatus])



    const collapseLogIn = () => {
        var Login = document.getElementById("collapseOne");
        var collapseLogin = new Collapse(Login, { toggle: false });

        var Sigin = document.getElementById("collapseTwo");
        var collapseSigin = new Collapse(Sigin, { toggle: false });

        collapseLogin.toggle();
        collapseSigin.hide();
    }

    const collapseSignIn = () => {
        var Sigin = document.getElementById("collapseTwo");
        var collapseSigin = new Collapse(Sigin, { toggle: false });

        var Login = document.getElementById("collapseOne");
        var collapseLogin = new Collapse(Login, { toggle: false });

        collapseSigin.toggle();
        collapseLogin.hide();
    }


    return (
        <div className={styles.main_screen} >

            <h1 className="text-center sticky-top">Bienvenido a PAR</h1>

            {
                checkLoggedStatus ?
                    <LoadingPage /> :
                    <div className={` ${styles.log_screen}`} >

                        <button className={`btn btn-primary ${styles.log_btn} collapsed`} type="button" onClick={collapseLogIn}>
                            Ingresar
                        </button>
                        <div id="collapseOne" className={`collapse`} >
                            <Login />
                        </div>

                        <button className={`btn btn-primary ${styles.log_btn} collapsed`} type="button" onClick={collapseSignIn}>
                            Registrarse
                        </button>
                        <div id="collapseTwo" className={`collapse mb-4 ${styles.collapseContainer}`}>
                            <SignIn />
                        </div>

                    </div>
            }


        </div>
    );
}

export default WelcomePage;