import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from "./EditVideo.module.css";
import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';

import Select from 'react-select';

const EditVideo = ({ videoId }) => {

    const [user, setUser] = useState({});

    const [video, setVideo] = useState({});
    const [editVideo, setEditVideo] = useState({ preview: '', data: '' });

    const [name, setName] = useState("");
    const [payedViews, setPayedViews] = useState("");
    const [currentViews, setCurrentViews] = useState(0);
    const [question, setQuestion] = useState("");
    const [option1, setOption1] = useState("");
    const [option2, setOption2] = useState("");
    const [option3, setOption3] = useState("");
    const [option4, setOption4] = useState("");
    const [answer, setAnswer] = useState(1);
    const [url, setUrl] = useState("");
    
    const [optionSelected, setOptionSelected] = useState();

    const [cookies, setCookies] = useCookies();
    const [errors, setErrors] = useState("");


    const selectOptions = [
        { value: 1, label: 'Option 1' },
        { value: 2, label: 'Option 2' },
        { value: 3, label: 'Option 3' },
        { value: 4, label: 'Option 4' }
    ];

    const history = useHistory();

    useEffect(() => {

        if ("usertoken" in cookies) {

            axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                .then(result => {

                    const asArray = Object.entries(result.data.message[0]);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    setUser(justStrings);
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
        else {
            console.log("401");
            history.push("/");
        }

        axios.get("/api/video/" + videoId, { withCredentials: true })
            .then(result => {
                setVideo(result.data.message[0]);
                setName(result.data.message[0].name);
                setUrl(result.data.message[0].url)
                setPayedViews(result.data.message[0].payedViews);
                setCurrentViews(result.data.message[0].currentViews);
                setQuestion(result.data.message[0].question);
                setOption1(result.data.message[0].options[0][0]);
                setOption2(result.data.message[0].options[1][0]);
                setOption3(result.data.message[0].options[2][0]);
                setOption4(result.data.message[0].options[3][0]);


                for (const item in result.data.message[0].options) {
                    if (result.data.message[0].options[item][1]) {
                        setOptionSelected(parseInt(item) + 1);
                        console.log(parseInt(item) + 1);

                    }
                }


            })
            .catch(err => console.log("Error: " + err));
    }, [])


    const sendForm = (e) => {
        e.preventDefault()
        let formData = new FormData()

        formData.append('file', editVideo.data)
        axios.post("/api/video/save", formData)
            .then(result => {
                console.log(result);
                axios.put("/api/video/" + videoId, {
                    name,
                    payedViews,
                    currentViews,
                    question,
                    url,
                    options: [
                        [option1, answer == 1 ? true : false],
                        [option2, answer == 2 ? true : false],
                        [option3, answer == 3 ? true : false],
                        [option4, answer == 4 ? true : false]
                    ]
                }, { withCredentials: true })
                    .then(result => {
                        console.log("DB Result: " + result);
                        setTimeout(() => {
                            history.push("/dashboard");
                        }, 1000)
                    })
                    .catch(err => {
                        setErrors(err.response.data.errors);
                        console.log(err.response.data.errors);
                        console.log("DB Error: " + err);
                    })
            })
            .catch(err => console.log("Error: " + err));
    }

    const handleFileChange = (e) => {

        try {
            const vid = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            }
            setEditVideo(vid);
            setUrl(vid.data.name);
        } catch (error) {
            console.log("Error: " + error);
            setEditVideo({ preview: '', data: '' });
        }

    }


    return (
        <div className={styles.formContainer}>
            <h2 className="text-center">Editar Comercial</h2>
            <form onSubmit={sendForm}>
                <div className="form-group">
                    <label htmlFor="name">Nombre:</label>
                    <input type="text" name="name" id="name" className="form-control" required onChange={(e) => setName(e.target.value)} value={name} />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Vistas pagas:</label>
                    <input type="number" name="payedViews" id="payedViews" className="form-control" required onChange={(e) => setPayedViews(e.target.value)} value={payedViews} />
                </div>
                <div className="form-group">
                    <label htmlFor="question">Pregunta:</label>
                    <input type="text" name="question" id="question" className="form-control" required onChange={(e) => setQuestion(e.target.value)} value={question} />
                </div>
                <div className="form-group">
                    <label htmlFor="option1">Opción 1:</label>
                    <input type="text" name="option1" id="option1" className="form-control" required onChange={(e) => setOption1(e.target.value)} value={option1} />
                </div>
                <div className="form-group">
                    <label htmlFor="option2">Opción 2:</label>
                    <input type="text" name="option2" id="option2" className="form-control" required onChange={(e) => setOption2(e.target.value)} value={option2} />
                </div>
                <div className="form-group">
                    <label htmlFor="option3">Opción 3:</label>
                    <input type="text" name="option3" id="option3" className="form-control" required onChange={(e) => setOption3(e.target.value)} value={option3} />
                </div>
                <div className="form-group">
                    <label htmlFor="option4">Opción 4:</label>
                    <input type="text" name="option4" id="option4" className="form-control" required onChange={(e) => setOption4(e.target.value)} value={option4} />
                </div>

                <div className="form-group">
                    <label htmlFor="answer">Respuesta:</label>
                    <select name="answer" id="answer" className="form-control" required onChange={(e) => setAnswer(e.target.value)} defaultValue={optionSelected}>
                        <option value={1}>Opción 1</option>
                        <option value={2}>Opción 2</option>
                        <option value={3}>Opción 3</option>
                        <option value={4}>Opción 4</option>
                    </select>
                </div>
                <div className='form-group'>
                    <label htmlFor="file">Video:</label>
                    <input type='file' name='file' onChange={handleFileChange} className="form-control" accept='video/mp4'></input>
                </div>

                {
                    editVideo.preview ?
                        <video src={editVideo.preview} controls className={`mt-3 mb-2 ${styles.previewVideo}`} /> :
                        <video src={process.env.PUBLIC_URL + '/static/video/' + video.url} controls className={`mt-4 mb-2 w-100`} id="promoVideo"></video>
                }
                <br />
                <button type='submit' className="btn btn-primary">Actualizar</button>

            </form>
        </div>
    );

}

export default EditVideo;