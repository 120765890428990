import UserMenu from '../UserMenu/UserMenu';
import styles from "./AdminPage.module.css";
import CreateVideo from "../CreateVideo/CreateVideo";
import HomeBtn from '../HomeBtn/HomeBtn';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import BackBtn from '../BackBtn/BackBtn';

const AdminPage = () => {

    const [user, setUser] = useState({});
    const [cookies, setCookies] = useCookies();

    const history = useHistory();
    useEffect(() => {

        if ("usertoken" in cookies) {
            axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                .then(result => {

                    const asArray = Object.entries(result.data.message[0]);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    setUser(justStrings);
                    if (justStrings.category !== "admin") {
                        history.push("/");
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
        else {
            console.log("401");
            history.push("/");
        }

    }, [])

    return (
        <div className={`${styles.main_screen}`}>
            <div className={`sticky-top ${styles.topBar}`}>
                <div className={styles.flex_1}>
                    <UserMenu />
                </div>
                <h1>PAR</h1>
                <div className={styles.flex_1}>
                    <BackBtn/>
                </div>
            </div>
            <div className={styles.main_content}>
                <CreateVideo />
            </div>

            <div className={`${styles.bottomBar}`}>
                <HomeBtn />
            </div>
        </div>
    );
}

export default AdminPage;