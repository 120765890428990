import React, { useState } from 'react';
import axios from 'axios';
import styles from "./CreatePrice.module.css";
import LoadingInfo from "../LoadingInfo/LoadingInfo"
import { useHistory } from 'react-router-dom';


const CreatePrice = () => {

    const [image, setImage] = useState({ preview: '', data: '' })
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const [given, setGiven] = useState(0);
    const [url, setUrl] = useState("");


    const [errors, setErrors] = useState("");

    const [waitResponse, setWaitResponse] = useState(false);

    const history = useHistory();

    const sendForm = (e) => {
        e.preventDefault();
        setWaitResponse(true);
        let formData = new FormData()
        formData.append('file', image.data)
        axios.post("/api/price/save", formData)
            .then(result => {
                console.log(result);
                axios.post("/api/price", {
                    name,
                    amount,
                    given,
                    url
                }, { withCredentials: true })
                    .then(result => {
                        setTimeout(() => {
                            setWaitResponse(false);
                            history.push("/dashboard");
                        }, 3000);
                    })
                    .catch(err => {
                        setErrors(err.response.data.errors);
                        setWaitResponse(false);
                    })
            })
            .catch(err => setWaitResponse(false));
    }

    const handleFileChange = (e) => {
        const vid = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        }
        setImage(vid);
        setUrl(vid.data.name);
    }


    return (
        <div className={styles.formContainer}>
            {
                waitResponse ?
                    <LoadingInfo /> :
                    null
            }
            <h2 className="text-center">Crear Premio</h2>
            <form onSubmit={sendForm}>
                <div className="form-group">
                    <label htmlFor="name">Nombre:</label>
                    <input type="text" name="name" id="name" className="form-control" required onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Cantidad:</label>
                    <input type="number" name="amount" id="amount" className="form-control" required onChange={(e) => setAmount(e.target.value)} />
                </div>
                <div className='form-group'>
                    <label htmlFor="file">Imagen:</label>
                    <input type='file' name='file' onChange={handleFileChange} required className="form-control" accept='image/png, image/jpeg ,image/jpg, image/webp'></input>
                </div>
                {image.preview && <img src={image.preview} alt="File not available" className={`mt-3 mb-2 ${styles.previewImage}`} />}
                <br />
                <button type='submit' className="btn btn-primary">Guardar</button>
            </form>
        </div>
    );

}

export default CreatePrice;