import React, { useEffect, useRef, useState } from "react";
import styles from './InstallBanner.module.css';


const InstallBanner = () => {

    const installPromptEvent = useRef(0);
    const [installable, setInstallable] = useState(false);
    useEffect(() => {
        window.addEventListener('beforeinstallprompt', event => {
            console.log("ABLED TO INSTALL");
            // Prevent Chrome <= 67 from automatically showing the prompt
            event.preventDefault();
            // Stash the event so it can be triggered later.
            installPromptEvent.current = event;
            console.log(installPromptEvent);
            // Update the install UI to notify the user app can be installed
            document.getElementById("banner").classList.add("show");
            document.querySelector('#installBtn').disabled = false;
            document.querySelector('#installBtn').addEventListener("click", () => {
                console.log("BUTTON PRESSED");
                console.log(installPromptEvent);
                // Update the install UI to remove the install button
                document.querySelector('#installBtn').disabled = true;
                // Show the modal add to home screen dialog
                installPromptEvent.current.prompt();
                // Wait for the user to respond to the prompt
                installPromptEvent.current.userChoice.then(choice => {
                    if (choice.outcome === 'accepted') {
                        console.log('User accepted the A2HS prompt');
                    } else {
                        console.log('User dismissed the A2HS prompt');
                    }
                    // Clear the saved prompt since it can't be used again
                    installPromptEvent.current = null;
                });
            });
            
        });

    }, [])

    return (
        <>
                <div className={`alert alert-secondary alert-dismissible fade ${styles.banner}`} role="alert" id="banner">
                    <h6>Para una mejor experiencia puedes instalar nuestra app</h6>
                    <button className="btn btn-primary mx-2" id="installBtn" disabled >Install</button>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div> 
        </>
    );
}

export default InstallBanner;