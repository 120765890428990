import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from './ScanBtn.module.css';


import scanLogo from "./assets/icons/qrscanner.svg"


const ScanBtn = () => {

    const history = useHistory();

    const dashboard = () => {
                history.push("/scanprize");
    }

    return (

        <button className={`btn btn-success ${styles.scanBtn}`} type="button" onClick={dashboard}><img src={scanLogo} alt="" /><span>Scan Prize</span></button>
    );


}

export default ScanBtn;