
export function getAllIndexes(arr, val) {
    var indexes = [], i = -1;
    while ((i = arr.indexOf(val, i + 1)) != -1) {
        indexes.push(i);
    }
    return indexes;
}

export function uniqueItems(arr) {
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    return arr.filter(onlyUnique);
}