import React from "react";
import { useHistory } from "react-router-dom";
import styles from './GameSettingsBtn.module.css';


const GameSettingsBtn = () => {

    const history = useHistory();

    const dashboard = () => {
        history.push("/settings");
    }

    return (

        <button className={`btn btn-info ${styles.gameSettingsBtn}`} type="button" onClick={dashboard}><span>Configuraciones</span></button>
    );


}

export default GameSettingsBtn;