import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useParams } from 'react-router-dom';
import HomeBtn from '../HomeBtn/HomeBtn';
import styles from "./ClaimPrizePage.module.css";
import jwt_decode from 'jwt-decode';

import { getAllIndexes } from '../Utils/arrays';
import { getCurrentDate } from '../Utils/date';
import { set } from 'mongoose';
import LoadingInfo from '../LoadingInfo/LoadingInfo';

const ClaimPrizePage = () => {

    const { userId, prizeId } = useParams();
    const [user, setUser] = useState(null);
    const [prize, setPrize] = useState();
    const history = useHistory();
    const [cookies, setCookies] = useCookies();
    const [updating, setUpdating] = useState(false);
    var prizePos = null;

    useEffect(() => {

        if ("usertoken" in cookies) {
            axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                .then(result => {

                    const asArray = Object.entries(result.data.message[0]);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    if (justStrings.category !== "admin" && justStrings.category !== "cashier") {
                        history.push("/");
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
        else {
            console.log("401");
            history.push("/");
        }

        axios.get("/api/users/" + userId, { withCredentials: true })
            .then(result => {

                const asArray = Object.entries(result.data.message[0]);
                const filtered = asArray.filter(([key, value]) => key !== 'password');
                const justStrings = Object.fromEntries(filtered);
                console.log(justStrings);
                setUser(justStrings);
            })
            .catch(err => {
                console.log("Error: " + err);
                history.push("/");
            });
        axios.get("/api/price/" + prizeId, { withCredentials: true })
            .then(result => {
                setPrize(result.data.message[0]);
                console.log(result.data.message[0]);
            })
            .catch(err => console.log("Error: " + err));
    }, []);

    const verifyPrize = () => {
        if (user != null) {
            if (user?.prizes.indexOf(prizeId) !== -1) {
                let idxs = getAllIndexes(user?.prizes, prizeId);
                console.log(idxs);
                for (const idx in idxs) {
                    if (user.claimDate[idxs[idx]] == '') {
                        console.log("FOUND");
                        prizePos = idxs[idx];
                        return true;
                    }

                }
                prizePos = idxs.at(-1);
                console.log(prizePos);
                console.log(user.winDate[prizePos]);
            }
        }
        return false;
    };

    const claimPrize = (element) => {
        console.log(user);
        user.claimDate[prizePos] = getCurrentDate();


        axios.put("/api/users/" + userId, {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
            birthdate: user.birthdate,
            experience: user.experience,
            coins: user.coins,
            prizes: user.prizes,
            winDate: user.winDate,
            claimDate: user.claimDate,
        }, { withCredentials: true })
            .then(result => {
                const asArray = Object.entries(result.data);
                const filtered = asArray.filter(([key, value]) => key !== 'password');
                const justStrings = Object.fromEntries(filtered);
                setUser(justStrings);
                console.log(justStrings);
                setUpdating(true);
                setTimeout(() => {
                    history.push("/main");
                }, 3000);

            })
            .catch(err => console.log("Error: " + err));

    }


    return (
        <div className={`${styles.main_screen}`}>
            {
                updating ?
                    <LoadingInfo message="Reclamado Correctamente" /> :
                    null
            }

            <div className={`sticky-top ${styles.topBar}`}>
                <div className={styles.flex_1}>
                </div>
                <h1>PAR</h1>
                <div className={styles.flex_1}>
                </div>
            </div>
            <div className={`${styles.video_main_content}`}>
                <table className={`table table-hover ${styles.tableInfo}`}>
                    <thead>
                        <tr>
                            <th colSpan={2} className="text-center">Información</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><strong>Cliente</strong></td>
                            <td>{user?.firstName} {user?.lastName}</td>
                        </tr>
                        <tr>
                            <td><strong>Email</strong></td>
                            <td>{user?.email}</td>
                        </tr>
                        <tr>
                            <td><strong>Premio</strong></td>
                            <td>{prize?.name}</td>
                        </tr>
                        <tr>
                            <td><strong>Estado</strong></td>
                            <td>{verifyPrize() ? "Disponible" : "No Disponible"}</td>
                        </tr>
                        <tr>
                            <td><strong>Ganado</strong></td>
                            <td>{user?.winDate[prizePos]}</td>
                        </tr>

                        {verifyPrize() ?
                            <tr>
                                <td colSpan={2} className="text-center"><button className={`btn btn-primary ${styles.claimBtn}`} type="button" onClick={() => { claimPrize() }}>Reclamar</button></td>
                            </tr> :
                            <tr>
                                <td><strong>Reclamado</strong></td>
                                <td>{user?.claimDate[prizePos]}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            <div className={` ${styles.bottomBar}`}>
                <HomeBtn />
            </div>
        </div>
    );
}

export default ClaimPrizePage;