import DailyPromos from '../DailyPromos/DailyPromos';
import PlayBtn from '../PlayBtn/PlayBtn';
import UserMenu from '../UserMenu/UserMenu';
import styles from "./MainPage.module.css";
import HomeBtn from "../HomeBtn/HomeBtn";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';
import LoadingMain from '../LoadingMain/LoadingMain';
import InstallBanner from '../InstallBanner/InstallBanner';



const MainPage = () => {

    const [user, setUser] = useState("");
    const [cookies, setCookie] = useCookies();
    const history = useHistory();

    useEffect(() => {

        if ("usertoken" in cookies) {
            axios.get("/api/users/" + jwt_decode(cookies.usertoken)._id, { withCredentials: true })
                .then(result => {

                    const asArray = Object.entries(result.data.message[0]);
                    const filtered = asArray.filter(([key, value]) => key !== 'password');
                    const justStrings = Object.fromEntries(filtered);
                    setUser(justStrings);
                    if (justStrings.category === "cashier") {
                        history.push("/scanprize");
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    history.push("/");
                });
        }
        else {
            console.log("401");
            history.push("/");
        }
        
    }, [])

    return (
        <div className={`${styles.main_screen}`}>
            <div className={`sticky-top ${styles.topBar}`}>
                <div className={styles.flex_1}>
                    <UserMenu />
                </div>
                <h1>PAR</h1>
                <div className={styles.flex_1}>
                </div>
            </div>
            <div className={`${styles.main_content} ${styles.centerContent}`}>
                {
                    user == "" ?
                        <LoadingMain /> :
                        <>
                            <DailyPromos />
                            <PlayBtn />
                            <InstallBanner />
                        </>
                }

            </div>

            <div className={`${styles.bottomBar}`}>
                <HomeBtn />
            </div>
        </div>
    );
}

export default MainPage;