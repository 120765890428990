import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from './PlayBtn.module.css';


import coinLogo from "./assets/icons/coin.svg"


const PlayBtn = () => {

    const history = useHistory();

    const play = () => {
        history.push("/play");
    }

    return (
        <div className={`${styles.playDiv}`}>
            <Link to="/play" className={`btn ${styles.playBtn}`} type="button"><img src={coinLogo} alt="play" /></Link>
            <h2 className="text-center ">Jugar</h2>
        </div>
    );


}

export default PlayBtn;