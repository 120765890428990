import React from 'react'
import styles from "./Dice.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

const Die = (props) => {

    const { face, rolling } = props

    // Using font awesome icon to show
    // the exactnumber of dots
    return (

        <FontAwesomeIcon icon={['fas', `fa-dice-${face}`]} className={`${styles.Dice} ${rolling && styles.DiceShaking}`} />

    );
}


export default Die