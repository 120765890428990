import React, { useState } from 'react';
import axios from 'axios';
import styles from "./CreateVideo.module.css";
import LoadingInfo from "../LoadingInfo/LoadingInfo"
import { useHistory } from 'react-router-dom';


const CreateVideo = () => {

    const [video, setVideo] = useState({ preview: '', data: '' })
    const [name, setName] = useState("");
    const [payedViews, setPayedViews] = useState("");
    const [currentViews, setCurrentViews] = useState(0);
    const [question, setQuestion] = useState("");
    const [option1, setOption1] = useState("");
    const [option2, setOption2] = useState("");
    const [option3, setOption3] = useState("");
    const [option4, setOption4] = useState("");
    const [answer, setAnswer] = useState(1);
    const [url, setUrl] = useState("");
    const [options, setOptions] = useState([]);

    const [errors, setErrors] = useState("");

    const [waitResponse, setWaitResponse] = useState(false);

    const history = useHistory();

    const sendForm = (e) => {
        e.preventDefault();
        setWaitResponse(true);
        let formData = new FormData()
        formData.append('file', video.data)
        axios.post("/api/video/save", formData)
            .then(result => {
                console.log(result);
                axios.post("/api/video", {
                    name,
                    payedViews,
                    currentViews,
                    question,
                    url,
                    options: [
                        [option1, answer == 1 ? true : false],
                        [option2, answer == 2 ? true : false],
                        [option3, answer == 3 ? true : false],
                        [option4, answer == 4 ? true : false]
                    ]
                }, { withCredentials: true })
                    .then(result => {

                        setTimeout(() => {
                            setWaitResponse(false);
                            history.push("/dashboard");
                        }, 3000);

                    })
                    .catch(err => {
                        setErrors(err.response.data.errors);
                        setWaitResponse(false)
                    })
            })
            .catch(err => setWaitResponse(false));
    }

    const handleFileChange = (e) => {
        const vid = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        }
        setVideo(vid);
        setUrl(vid.data.name);
    }


    return (
        <div className={styles.formContainer}>
            {
                waitResponse ?
                    <LoadingInfo /> :
                    null
            }
            <h2 className="text-center">Crear Comercial</h2>
            <form onSubmit={sendForm}>
                <div className="form-group">
                    <label htmlFor="name">Nombre:</label>
                    <input type="text" name="name" id="name" className="form-control" required onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Vistas pagas:</label>
                    <input type="number" name="payedViews" id="payedViews" className="form-control" required onChange={(e) => setPayedViews(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="question">Pregunta:</label>
                    <input type="text" name="question" id="question" className="form-control" required onChange={(e) => setQuestion(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="option1">Opción 1:</label>
                    <input type="text" name="option1" id="option1" className="form-control" required onChange={(e) => setOption1(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="option2">Opción 2:</label>
                    <input type="text" name="option2" id="option2" className="form-control" required onChange={(e) => setOption2(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="option3">Opción 3:</label>
                    <input type="text" name="option3" id="option3" className="form-control" required onChange={(e) => setOption3(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="option4">Opción 4:</label>
                    <input type="text" name="option4" id="option4" className="form-control" required onChange={(e) => setOption4(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="answer">Respuesta:</label>
                    <select name="answer" id="answer" className="form-control" required onChange={(e) => { setAnswer(e.target.value) }}>
                        <option value="1">Opción 1</option>
                        <option value="2">Opción 2</option>
                        <option value="3">Opción 3</option>
                        <option value="4">Opción 4</option>
                    </select>
                </div>
                <div className='form-group'>
                    <label htmlFor="file">Video:</label>
                    <input type='file' name='file' onChange={handleFileChange} required className="form-control" accept='video/mp4'></input>
                </div>
                {video.preview && <video src={video.preview} controls className={`mt-3 mb-2 ${styles.previewVideo}`} />}
                <br />
                <button type='submit' className="btn btn-primary">Guardar</button>
            </form>
        </div>
    );

}

export default CreateVideo;