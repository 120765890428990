import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from './SignIn.module.css';


const Login = () => {


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [email, setEmail] = useState("");
    const [cellphone, setCellphone] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [errors, setErrors] = useState("");
    const [accepted, setAccepted] = useState(false);

    const history = useHistory();

    const signin = (e) => {
        e.preventDefault();

        axios.post("/api/register", {
            firstName,
            lastName,
            email,
            cellphone,
            password,
            confirmPassword: passwordConfirm,
            birthdate

        }, { withCredentials: true })
            .then(res => {
                console.log(res);
                history.push("/main");
            })
            .catch(err => {
                console.log(err.response.data.errors);
                setErrors(err.response.data.errors)
            });
    }

    return (

        <div className={styles.loginCard}>
            <form onSubmit={(e) => signin(e)}>
                <h2 className="text-center">Crear cuenta</h2>
                <div className={styles.formGroup}>
                    <label htmlFor="">Nombre</label>
                    <input type="text" className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    {errors.firstName ? <span className="text-danger">{errors.firstName.message}</span> : null}
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="">Apellido</label>
                    <input type="text" className="form-control" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    {errors.lastName ? <span className="text-danger">{errors.lastName.message}</span> : null}
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="">Fecha de nacimiento</label>
                    <input type="date" placeholder="dd-mm-yyyy" className="form-control" value={birthdate} onChange={(e) => setBirthdate(e.target.value)} />
                    {errors.birthdate ? <span className="text-danger">{errors.birthdate.message}</span> : null}
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="">Email</label>
                    <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                    {errors.email ? <span className="text-danger">{errors.email.message}</span> : null}
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="">Celular</label>
                    <input type="number" className="form-control" value={cellphone} onChange={(e) => setCellphone(e.target.value)} />
                    {errors.cellphone ? <span className="text-danger">{errors.cellphone.message}</span> : null}
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="">Contraseña (min 8 caracteres)</label>
                    <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                    {errors.password ? <span className="text-danger">{errors.password.message}</span> : null}
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="">Confirmar Contraseña</label>
                    <input type="password" className="form-control" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                    {errors.confirmPassword ? <span className="text-danger">{errors.confirmPassword.message}</span> : null}
                </div>
                <div className={styles.formGroup}>
                    <input type="checkbox" className="me-2" value={passwordConfirm} onChange={(e) => setAccepted(e.target.checked)} />
                    <label htmlFor="">Acepto <a href={process.env.PUBLIC_URL + '/static/pdf/terminos.pdf'} download>términos y condiciones</a> </label>
                </div>

                <button type="submit" disabled={!accepted} className={`btn ${styles.btnBlue}`}>Crear</button>

            </form>

        </div>

    );


}

export default Login;