import React from "react";
import styles from './CreatePrizeBtn.module.css';


import plusLogo from "./assets/icons/plus.svg"
import { Link } from "react-router-dom";



const CreatePrizeBtn = () => {


    return (
            <Link to="/create/price" className={`btn ${styles.addVideoBtn}`} type="button"><img src={plusLogo} alt="Menu" /></Link>
    );


}

export default CreatePrizeBtn;