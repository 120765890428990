import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from './AdminBtn.module.css';


import adminLogo from "./assets/icons/logout.svg"


const AdminBtn = () => {

    const history = useHistory();

    const dashboard = () => {
                history.push("/dashboard");
    }

    return (

        <button className={`btn btn-warning ${styles.adminBtn}`} type="button" onClick={dashboard}><img src={adminLogo} alt="" /><span>Dashboard</span></button>
    );


}

export default AdminBtn;