import HomeBtn from '../HomeBtn/HomeBtn';
import PlayVideo from '../PlayVideo/PlayVideo';
import UserMenu from '../UserMenu/UserMenu';
import styles from "./VideoPage.module.css";
import BackBtn from '../BackBtn/BackBtn';

const VideoPage = () => {
    return (
        <div className={`${styles.main_screen}`}>
            <div className={`sticky-top ${styles.topBar}`}>
                <div className={styles.flex_1}>
                    <UserMenu />
                </div>
                <h1>PAR</h1>
                <div className={styles.flex_1}>
                <BackBtn/>
                </div>
            </div>
            <div className={`${styles.video_main_content}`}>
                <PlayVideo />
            </div>

            <div className={` ${styles.bottomBar}`}>
                <HomeBtn/>
            </div>
        </div>
    );
}

export default VideoPage;